import React from 'react';
import ReactWhatsapp from 'react-whatsapp';


import filaSalmao from '../../images/fileSalmao.png';
import filaDefumado from '../../images/fileDefumado.png';
import camaraoCinza5290 from '../../images/camaraoCinza5290.png';
import camaraoRosa2290 from '../../images/camaraoRosa2290.png';
import camaraoCinza4690 from '../../images/camaraoCinza4690.png';
import camaraoCinza2780 from '../../images/camaraoCinza2780.png';
import camaraoCinza1980 from '../../images/camaraoCinza1980.png';
import camaraoCinza4290 from '../../images/camaraoCinza4290.png';
import camarao3990 from '../../images/camarao3990.png';
import camarao2490 from '../../images/camarao2490.png';
import camarao1990 from '../../images/camarao1990.png';
import camarao3390 from '../../images/camarao3390.png';
import camarao from '../../images/camarao.png';
import camarao8990 from '../../images/camarao8990.png';
import file4390 from '../../images/file4390.png';
import file5190 from '../../images/file5190.png';
import file7215 from '../../images/file7215.png';
import file2990 from '../../images/file2990.png';
import file from '../../images/file.png';
import tilapia from '../../images/tilapia.png';
import file3190 from '../../images/file3190.png';
import posta2100 from '../../images/posta2100.png';
import posta2100a from '../../images/posta2100a.png';
import posta3890 from '../../images/posta3890.png';
import cabeca890 from '../../images/cabeca890.png';
import posta3780a from '../../images/posta3780a.png';
import posta from '../../images/posta.png';
import posta2690 from '../../images/posta2690.png';
import posta2390 from '../../images/posta2390.png';
import lombo5890 from '../../images/lombo5990.png';
import polpa1990 from '../../images/polpa1990.png';
import file4890 from '../../images/file4890.png';

import {
    Container,
    DivImage,
    ImageProduct,
    DivCodigo,
    Codigo,
    DivName,
    Name,
    DivPrice,
    PriceDe,
    PricePor,
    DivNamePrice,
    DivGeral,
    WeightProduct,
    Teste,
    TesteA,
    LinkZap
} from './styled';

const itens = [
    {
        id:1,
        image: lombo5890,
        name: 'Lombo Salmão cong.',
        weightProduct: 'Bandeja +/- 600G',
        code: 'Cód. 4249',
        priceDe: '69,80',
        pricePor: '58,90'
    },
    {
        id:1,
        image: polpa1990,
        name: 'Polpa de Salmão ',
        weightProduct: 'Pacote 500g',
        code: 'Cód. 4250',
        priceDe: '',
        pricePor: '19,90'
    },
    {
        id:1,
        image: file4890,
        name: 'File Salmão cong.',
        weightProduct: 'Bandeja +/- 500g',
        code: 'Cód. 4283',
        priceDe: '',
        pricePor: '48,90'
    },
    {
        id:1,
        image: filaSalmao,
        name: 'Filé de Salmão',
        weightProduct: 'Pacote 500G',
        code: 'Cód. 4287',
        priceDe: '37,90',
        pricePor: '27,90'
    },
    {
        id:2,
        image: filaDefumado,
        name: 'Filé de Salmão Defumado e fatiado',
        weightProduct: 'Pacote 100G',
        code: 'Cód. 4341',
        priceDe: '',
        pricePor: '18,80'
    },
    {
        id:3,
        image: camaraoCinza5290,
        name: 'Camarão Cinza desc. Tailon G. 21/25',
        weightProduct: 'Pacote 500G',
        code: 'Cód. 4272',
        priceDe: '',
        pricePor: '52,90'
    },
    {
        id:4,
        image: camaraoRosa2290,
        name: 'Camarão Rosa CC G. 40/50',
        weightProduct: 'Pacote 500G',
        code: 'Cód. 4304',
        priceDe: '',
        pricePor: '22,90'
    },
    {
        id:5,
        image: camaraoCinza4690,
        name: 'Camarão Cinza S/C G. 21/25',
        weightProduct: 'Pacote 500G',
        code: 'Cód. 4273',
        priceDe: '',
        pricePor: '46,90'
    },
    {
        id:6,
        image: camaraoCinza2780,
        name: 'Camarão Cinza S/C M. 41/50',
        weightProduct: 'Pacote 500G',
        code: 'Cód. 4276',
        priceDe: '',
        pricePor: '27,80'
    },
    {
        id:7,
        image: camaraoCinza1980,
        name: 'Camarão Cinza S/C P. 61/70 ',
        weightProduct: 'Pacote 500G',
        code: 'Cód. 4238',
        priceDe: '',
        pricePor: '19,80'
    },
    {
        id:8,
        image: camaraoCinza4290,
        name: 'Camarão Cinza desc. Tailon M. 41/50 ',
        weightProduct: 'Pacote 500G',
        code: 'Cód. 4275',
        priceDe: '',
        pricePor: '42,90'
    },
    {
        id:9,
        image: camarao3990,
        name: 'Camarão Cinza desc. M. 41/50 ',
        weightProduct: 'Pacote 500G',
        code: 'Cód. 4277',
        priceDe: '',
        pricePor: '39,90'
    },
    {
        id:10,
        image: camarao2490,
        name: 'Camarão Sete Barba desc. P. 100/200',
        weightProduct: 'Pacote 500G',
        code: 'Cód. 4274',
        priceDe: '',
        pricePor: '24,90'
    },
    {
        id:11,
        image: camarao1990,
        name: 'Camarão Cinza C/C P. 80/100',
        weightProduct: 'Pacote 500G',
        code: 'Cód. 3807',
        priceDe: '',
        pricePor: '19,90'
    },
    {
        id:12,
        image: camarao3390,
        name: 'Camarão Cinza desc. P. 61/70',
        weightProduct: 'Pacote 500G',
        code: 'Cód. 4239',
        priceDe: '',
        pricePor: '33,90'
    },
    {
        id:13,
        image: camarao,
        name: 'Camarão Rosa C/C GG. 11/15',
        weightProduct: 'Pacote 500G',
        code: 'Cód. 4344',
        priceDe: '',
        pricePor: '59,90'
    },
    {
        id:14,
        image: camarao8990,
        name: 'Camarão Rosa Desc. Tailon GG. 08/10',
        weightProduct: 'Pacote 500G NAC',
        code: 'Cód. 4435',
        priceDe: '',
        pricePor: '89,90'
    },
    {
        id:15,
        image: camarao8990,
        name: 'Camarão Rosa Desc. Tailon G. 21/25',
        weightProduct: 'Pacote 500G NAC',
        code: 'Cód. 4446',
        priceDe: '',
        pricePor: '59,80'
    },
    {
        id:16,
        image: camaraoCinza4290,
        name: 'Camarão Cinza desc. Tailon 31/35',
        weightProduct: 'Pacote 500G NAC',
        code: 'Cód. 4456',
        priceDe: '',
        pricePor: '48,90'
    },
    {
        id:17,
        image: camaraoCinza4290,
        name: 'Camarão Cinza desc. Tailon 31/35',
        weightProduct: 'Pacote 500G NAC',
        code: 'Cód. 4456',
        priceDe: '',
        pricePor: '48,90'
    },
    {
        id:18,
        image: file4390,
        name: 'Filé Dourado',
        weightProduct: 'Pacote 1kg',
        code: 'Cód. 4257',
        priceDe: '',
        pricePor: '43,90'
    },
    {
        id:19,
        image: file5190,
        name: 'Filé Pescada Amarela G. a vácuo',
        weightProduct: 'Peso variado',
        code: 'Cód. 4255',
        priceDe: '',
        pricePor: '51,90'
    },
    {
        id:20,
        image: file7215,
        name: 'Filé Camurim a vácuo',
        weightProduct: 'Peso variado',
        code: 'Cód. 4254',
        priceDe: '',
        pricePor: '72,15'
    },
    {
        id:21,
        image: file2990,
        name: 'Filé Panga Premium',
        weightProduct: 'Pacote 1kg',
        code: 'Cód. 4259',
        priceDe: '',
        pricePor: '29,90'
    },
    {
        id:22,
        image: file5190,
        name: 'Filé Pescada Amarela M. a vácuo',
        weightProduct: 'Pacote +/- 600g',
        code: 'Cód. 4256',
        priceDe: '',
        pricePor: '36,90'
    },
    {
        id:23,
        image: file,
        name: 'Filé de Merluza',
        weightProduct: 'Pacote 1kg NAC',
        code: 'Cód. 4258',
        priceDe: '',
        pricePor: '27,50'
    },
    {
        id:24,
        image: tilapia,
        name: 'Filé de Tilápia',
        weightProduct: 'Pacote 1kg',
        code: 'Cód. 4251',
        priceDe: '',
        pricePor: '39,80'
    },
    {
        id:25,
        image: file3190,
        name: 'Posta Dourado',
        weightProduct: 'Pacote 1kg',
        code: 'Cód. 4289',
        priceDe: '',
        pricePor: '31,90'
    },
    {
        id:26,
        image: posta2100,
        name: 'Posta Corvina',
        weightProduct: 'Pacote 1kg',
        code: 'Cód. 4290',
        priceDe: '',
        pricePor: '21,00'
    },
    {
        id:27,
        image: posta2100a,
        name: 'Posta Serra',
        weightProduct: 'Pacote 1kg',
        code: 'Cód. 4291',
        priceDe: '',
        pricePor: '21,00'
    },
    {
        id:28,
        image: posta3890,
        name: 'Posta Pescada Amarela G.',
        weightProduct: 'Pacote 1kg',
        code: 'Cód. 4279',
        priceDe: '',
        pricePor: '38,90'
    },
    {
        id:29,
        image: posta,
        name: 'Posta Meca',
        weightProduct: 'Pacote 1kg',
        code: 'Cód. 4268',
        priceDe: '',
        pricePor: '37,80'
    },
    {
        id:30,
        image: posta3780a,
        name: 'Posta Cavala Branca',
        weightProduct: 'Pacote 1kg NAC',
        code: 'Cód. 4288',
        priceDe: '',
        pricePor: '37,80'
    },
    {
        id:31,
        image: cabeca890,
        name: 'Cabeça de Peixe (Pescada Amarela)',
        weightProduct: 'Pacote 1kg',
        code: 'Cód. 4280',
        priceDe: '',
        pricePor: '8,90'
    },
    {
        id:32,
        image: posta2690,
        name: 'Posta Pescada M.',
        weightProduct: '1kg',
        code: 'Cód. 4278',
        priceDe: '',
        pricePor: '26,90'
    },
    {
        id:33,
        image: posta2390,
        name: 'Posta Corvina',
        weightProduct: 'Pacote 800g NAC',
        code: 'Cód. 4453',
        priceDe: '',
        pricePor: '23,90'
    },
]

export default () =>{
    return(
        <Container>
            
            {itens.map((item, index) =>
                <DivGeral key={index}>
                    <LinkZap href={`https://api.whatsapp.com/send?phone=5581992432264&text=Essa mensagem pode ser customizada conforme deseja. Produto: ${item.name} ${item.code} Valor: ${item.pricePor}`} target="_blank">
                        <DivImage>
                            <ImageProduct src={item.image} alt=""/>
                            <DivCodigo>
                                <Codigo>{item.code}</Codigo>
                            </DivCodigo>
                        </DivImage>
                    </LinkZap>
                    
                    <DivNamePrice>
                        <DivName>
                            <Name>
                                {item.name}
                            </Name>
                            <WeightProduct>
                                {item.weightProduct}
                            </WeightProduct>
                        </DivName>
                        <DivPrice>
                            {item.priceDe != '' &&
                                <Teste>De: <PriceDe> R$ {item.priceDe}</PriceDe></Teste>
                            }
                            {item.priceDe != '' &&
                                <TesteA>POR:<PricePor> R$ {item.pricePor}</PricePor></TesteA>
                            }
                            {item.priceDe == '' &&
                                <PricePor> R$ {item.pricePor}</PricePor>
                            }
                            
                        </DivPrice>
                    </DivNamePrice>
                </DivGeral>
            )}
            
        </Container>
    );
}