import React from 'react';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import {
    Container,
    DivImageInstagram,
    DivImageZap,
    DivNumero,
    DivDescriptionInstagram
} from './styled';

export default () => {
    return(
        <Container>
            <DivImageInstagram>
                <InstagramIcon />
                <DivDescriptionInstagram>
                    @divinospescados 
                </DivDescriptionInstagram>
            </DivImageInstagram>
            <DivImageZap>
                <DivNumero>
                    (81) 9 9243-2267
                </DivNumero> 
                <WhatsAppIcon />
            </DivImageZap>
        </Container>
    );
}