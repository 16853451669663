import React from 'react';
import {
    Container
} from './styled';

export default () =>{
    return(
        <Container>
            <h1>SOBRE</h1>
        </Container>
    );
}