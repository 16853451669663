import React from 'react';

import {
    Container
} from './styled';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ListProduct from '../../components/ListProduct';


export default () =>{
    return(
        <Container>
            <Header />
            <ListProduct />
            <Footer />
        </Container>
    );
}