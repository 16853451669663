import styled from 'styled-components';

export const Container = styled.div`
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    color: white;
    border-top: 1px solid #2890B2;
    /* position:absolute;
    bottom:0;
    width:100%; */
`;

export const DivImageInstagram = styled.div`
    display: flex;
    align-items: center;
    padding-left: 10px;
`;
export const DivImageZap = styled.div`
    display: flex;
    align-items: center;
    padding-right: 10px;
`;
export const DivNumero = styled.div`
    margin-right: 10px;
    font-size: 20px;
    font-weight: bold
`;
export const DivDescriptionInstagram = styled.div`
    margin-left: 10px;
    font-size: 20px;
    font-weight: bold;
`;