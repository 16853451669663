import React from 'react';
import imageFooter from '../../images/footer.png';
import Menu from '../Menu';

import {
    Container,
    DivImage
} from './styled';

export default () => {
    return(
        <Container>
            <DivImage src={imageFooter} alt="" />
            {/* <Menu /> */}
        </Container>
    );
}