import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: white;
`;
export const DivImage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
`;
export const ImageProduct = styled.img`
    height: 151px;
    
`;
export const DivCodigo = styled.div`

`;
export const Codigo = styled.div`
    font-weight: bold;
    font-size: 20px;
`;

export const DivNamePrice = styled.div`
    display: flex;
    flex-direction: column;
    //justify-content: center;
    width: 400px;
    margin-left: 10px;
`;
export const DivName = styled.div`
    margin-bottom: 10px;
`;
export const Name = styled.div`
    font-size: 20px;
    font-weight: bold;
    word-wrap: break-word;    
`;
export const WeightProduct = styled.div`
    font-size: 15px;
    font-weight: bold;
    word-wrap: break-word;    
`;

export const DivPrice = styled.div`

`;
export const PriceDe = styled.div`
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #f90021;
`;
export const PricePor = styled.div`
    font-weight: bold;
    font-size: 30px;
    color: #2890b2;
`;

export const DivGeral = styled.div`
    margin-bottom: 30px;
    display: flex;
`;

export const Teste = styled.div`
    
`;
export const TesteA = styled.div`
    
`;
export const LinkZap = styled.a`
     text-decoration:none; 

`;